#launchContainer {
	width: 25%;
	height: auto;
	top: 10%;
	margin: auto;
	background-color: white;
	border-radius: 40px;
}

#Logo {
	position: relative;
	display: block;
	text-align: center;
	vertical-align: middle;
	float: none;
	padding: 10%;
	overflow: hidden;
	height: 100%;
	width: auto;
	overflow: hidden;
	background-color: white;
	border-radius: 15px;
	margin-left: auto;
	margin-right: auto;
}

img {
	height: 100%;
	width: 100%;
	object-fit: contain;
	object-position: center;
	background-color: white;
	vertical-align: middle;

}

@media screen and (max-width: 1000px) {

	#launchContainer {
		width: 90%;
		height: 90%;
		top: 10%;
		margin-top: 5%;
		background-color: white;
		border-radius: 0px;
	}

	#Logo {
		position: relative;
		display: block;
		text-align: center;
		vertical-align: middle;
		float: none;
		padding: 0%;
		overflow: hidden;
		height: auto;
		width: 80%;
		top: 10%;
		background-color: white;
		border-radius: 5%;
		margin-left: auto;
		margin-right: auto;
	}

	img {
		height: 100%;
		width: 20%;
		object-fit: contain;
		object-position: center;
		overflow: hidden;
		background-color: black;
	}
}





